html,
body {
  width: 100%;
  height: 100%;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  display: flex;
  gap: 12px;
}

.loading div {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  box-shadow: 0 -0.4rem rgba(60, 0, 80, 0.1) inset;

  animation: cycle 1s ease-in-out infinite;
}

.loading div:nth-child(1) {
  animation-delay: 0;
}

.loading div:nth-child(2) {
  animation-delay: 0.2s;
}

.loading div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes cycle {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(1rem);
  }

  100% {
    transform: translateY(0);
  }
}
