body {
  background-image: url("../imgs/voting-background.png");
  background-color: #cccccc;
  background-size: cover;
  background-position: center;
}
.container {
  position: absolute;
  top: 42%;
  left: 50%;
  width: 100%;
  max-height: 60%;
  transform: translate(-50%, -50%);
}
.containerTitle {
  background: "rgba(255, 255, 255, 0.5)";
  width: "100%";
  max-width: "80%";
  display: "inline-block";
}
